import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export const TermsOfUse: React.FC = () => {

    return (
        <div className="bv-terms-page page">
            <div className="breadcrumb-area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="breadcrumb-title text-center">
                                <h2>Terms And Conditions</h2>
                                <ul className="breadcrumb-list">
                                    <li>Home</li>
                                    <li> <FontAwesomeIcon icon={icon({ name: 'angle-double-right', style: 'solid' })} /> </li>
                                    <li>Terms Of Use</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-details-area section-padding-100-50 bg-gray-cu">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-details-content mb-50">
                                <h1 className="uk-margin-small-bottom">Terms And Conditions</h1>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">In these General Terms and Conditions the following terms have the following meanings, unless expressly stated otherwise:</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>General Terms and Conditions</em>: The general terms and conditions as set out hereinafter.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>Alchemist Algo Tradings</em>: Alchemist Algo Tradings, part of OliNo Energy B.V.</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>OliNo: </em>OliNo Energy B.V. registered with the Ch. of Comm. under number 17205823.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>Service</em>: All work, in whatsoever form, executed by Alchemist Algo Tradings for or for the benefit of the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>Client</em>: Those who have accepted the applicability of these General Terms and Conditions and provided an assignment for the provision of the service.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>Agreement</em>: Every Agreement concluded between Alchemist Algo Tradings and the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><em>Trader</em>: The fully automatic Bitcoin Trader who trades Bitcoins on behalf of the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Scope</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">These General Terms and Conditions apply to every offer and Agreement concluded between Alchemist Algo Tradings and the Client, unless parties expressly and in writing derogate from these General Terms and Conditions.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">These General Terms and Conditions also apply to Agreements with Alchemist Algo Tradings for the performance of which third parties must be involved.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The applicability of any purchase and/or other General Terms and Conditions of the Client is expressly rejected.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If it appears that one or more provisions of these General Terms and Conditions are null and void or voidable, these General Terms and Conditions will continue to be in existence for all the remainder.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Derogations from the Agreement and General Terms and Conditions will only be valid if these have been expressly agreed in writing with Alchemist Algo Tradings.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If Alchemist Algo Tradings does not always require strict compliance with these conditions, this will not mean that the provisions thereof are not applicable, or that Alchemist Algo Tradings to any extent would lose the right in other cases to require strict compliance with the provisions of these terms and conditions.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Offers</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Offers will be made in writing and/or electronically, unless urgent circumstances make this impossible.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">All offers from Alchemist Algo Tradings are without obligation, unless a period for acceptance is stated in the offer. If a period for acceptance is set out in the offer, the offer will lapse when this period has expired.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">All offers from Alchemist Algo Tradings are valid during the period included in the offer. The offer lapses when this period has expired.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings cannot be bound by its offers if the Client, in accordance with the standards of reasonableness and fairness and according to generally accepted standards, ought to have understood that the offer or a part thereof contains an obvious mistake or clerical error.</p><br />
                                <h3>Account</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The Client must generate an account to be able to use Alchemist Algo Tradings’s trading. The Client must, in doing this, fill in his/her personal data.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The Client will manage his/her account and must personally set this up.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">A minor will always require express permission from his/her parent or guardian.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">It is not permitted to provide the login details to third parties and/or to use a third party’s login details.</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will never be responsible or liable for the contents and/or information that is placed on the Client’s profile page.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Trading</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The Client must first generate a personal account with a third party Bitcoin exchange that is supported by Alchemist Algo Tradings, in order to let Alchemist Algo Tradings trade on behalf of the Client. Following this the Client must generate API keys with this exchange with minimal rights, as a result of which users of these API keys will only have the right to trade and request balances on behalf of the Client. The API keys expressly do NOT have the right to remove money or Bitcoins from the exchange. The Client will be personally responsible for generating API keys with minimal rights. The Client is aware that more risks are taken if API keys are generated with too many rights, because any hackers can misuse these keys to remove money or Bitcoins from the Bitcoin exchange account of the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The API keys are transferred to Alchemist Algo Tradings during the registration for Alchemist Algo Tradings. The Client thereby gives Alchemist Algo Tradings the right to and the possibility to be allowed to trade on behalf of the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If generated correctly by the Client, the API keys will not give Alchemist Algo Tradings any possibility of transferring money or Bitcoins to other accounts.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The Client needs at least one credit to be able to trade. If the number of credits is negative or zero, there can be no trading.</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The credits purchased by the Client are in any event not refundable.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p>
                                <h3>Termination of the Alchemist Algo Tradings account</h3>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top"><strong> </strong></p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">A Client can at any time unilaterally stop the use of the Alchemist Algo Tradings service.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">OliNo will be entitled to unilaterally terminate the account with immediate effect if:</p><br />
                                <ul>
                                <li>There is fraud or misuse on the part of the Client.</li>
                                <li>The Client’s actions cause damage for OliNo.</li>
                                <li>An account of the Client has had negative credits for longer than 2 months.</li>
                                </ul>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Performance of the Agreement</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will perform the Agreement to the best of its knowledge and ability and in accordance with high standards.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings has the right to have specific work executed by third parties. The applicability of Sections 404 and 407, subsection 2, Book 7 of the Civil Code is expressly excluded.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The work will be executed within a period stated by Alchemist Algo Tradings.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If a period has been agreed or stated for the execution of specific work, this period will only be indicative and is never to be regarded as a final deadline.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If Alchemist Algo Tradings requires data or instructions from the Client, which are necessary for the performance of the Agreement, the execution period will commence after Alchemist Algo Tradings has received this from the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Prices and rates</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The prices and/or rates are expressed in Euro, including VAT and other duties imposed by authorities, unless stated otherwise.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The prices and/or rates are including travel and accommodation, packaging, delivery, or dispatch costs, and administrative costs, unless stated otherwise.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Payment will take place by means of the purchase of pre-paid credits. These credits must be purchased online and paid electronically.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Payment will always take place in advance, unless agreed otherwise.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Return</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The trader’s annual return will be optimised by conducting simulations with real historical Bitstamp trading data.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The trader will only sell bitcoins if profit can be made. By having the strategy to only sell with profit we have made the assumption that Bitcoin will have an increasing price in the long-term. Because of this the trader can wait until the pockets with Bitcoin that have already been purchased can be sold again with profit. This means that in a prolonged monotonous bear market no return can be achieved in the worst case situation.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The trader’s return is very dependent on the market. If the Bitcoin’s price fluctuates considerably, the Client will achieve the best return with the trader.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Past return on investment is no guarantee for the future.</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Investment in Bitcoin is regarded by many people as high-risk and that also applies therefore to using the cryptotraders. Therefore never invest more than you can afford to lose.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p>
                                <h3>Liability</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will only be liable for direct damage arisen through wilful recklessness or intention on the part of Alchemist Algo Tradings. Direct damage is exclusively taken to mean:</p><br />
                                <ul>
                                <li>damage to property of the Client;</li>
                                <li>reasonable costs incurred by the Client for establishing liability and the (extent of the direct) damage;</li>
                                <li>reasonable costs, reasonably incurred by the Client, which could be and were permitted to be incurred in all reasonableness, for the prevention or limitation of the damage, insofar as the Client demonstrates that these costs have resulted in limitation of the direct damage;</li>
                                <li>reasonable costs, which the Client has incurred in all reasonableness for acquiring payment without the intervention of the courts, as referred to in Section 96, subsection 1, under c, Book 6 of the Civil Code.</li>
                                </ul>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will never be liable for the loss of money or Bitcoins due to problems with the Bitcoin exchange, or the careless transfer of money or Bitcoins by the Client from – or to – the Bitcoin exchange.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will never be liable for the loss of money or Bitcoins due to problems with Bitcoin or the Bitcoin network or the decrease in value of Bitcoin.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will never be liable for indirect loss, including in any event consequential loss, lost profit, lost savings, loss due to business interruption, or intangible loss on the part of the Client.</p><br /><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will not be liable for damage, of whatsoever nature, due to the fact that Alchemist Algo Tradings has proceeded from inaccurate and/or incomplete data provided by the Client, unless this inaccuracy or incompleteness ought to be apparent to Alchemist Algo Tradings.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The limitations of liability included in this article do not apply if the damage can be attributed to intention or gross negligence on the part of Alchemist Algo Tradings or its managerial employees.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If Alchemist Algo Tradings were to be liable for any damage whatsoever, the liability on the part of Alchemist Algo Tradings will be limited to a maximum amount of $ 7,500 or to the amount that the insurance taken out by Alchemist Algo Tradings gives entitlement to, plus the amount of the deductible which is not borne by the insurer under the policy conditions that Alchemist Algo Tradings accordingly must bear.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The client must report the damage, for which Alchemist Algo Tradings can be held liable, as soon as possible but in any event within 10 days from the arising of the damage, to Alchemist Algo Tradings, all this at the risk of forfeiting any right to compensation of this damage.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Any action for liability against Alchemist Algo Tradings lapses within one year after the Client became aware of the damage causing fact or could reasonably have become aware thereof.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Indemnity</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The client indemnifies Alchemist Algo Tradings against possible claims by third parties, who in connection with the performance of the Agreement suffer damage which is attributable to the Client.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If Alchemist Algo Tradings is held liable by the third parties on that basis, the Client will be obliged to assist Alchemist Algo Tradings at law and otherwise. All costs and damage on the part of the Alchemist Algo Tradings and third parties will further be at the Client’s expense and risk.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p>
                                <h3>Confidentiality</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings as well as the Client will be obliged, during the term and after the termination of the Agreement, to maintain the confidentiality of all facts and particulars concerning the enterprise, regarding which the Client knows or reasonably can suspect that these are confidential. This duty of confidentiality also contains all data of the employees, customers, clients and other business contacts that has come to one’s knowledge on the basis of the Assignment.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Limitation period</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">In derogation from the statutory limitation periods, a limitation period of one year applies to all claims towards Alchemist Algo Tradings and (any) third parties engaged by Alchemist Algo Tradings.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <h3>Privacy and cookies</h3>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The data and information provided by the Client to Alchemist Algo Tradings will be saved by Alchemist Algo Tradings with due care and confidentiality.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings is only and exclusively permitted to use the Client’s personal data in the context of the execution of its obligation to supply, or for dealing with a complaint.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">During visits to its website Alchemist Algo Tradings can collate, by means of cookies, information from the Client regarding the use of the website.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The information collated by Alchemist Algo Tradings by means of cookies can be used for functional and analytical purposes.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings is not permitted to lend, lease, sell, or in another manner disclose the Client’s personal data.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If, on the basis of a statutory provision or a judicial decision, Alchemist Algo Tradings will be obliged to provide confidential information to third parties, and Alchemist Algo Tradings cannot rely concerning this on a statutory right to decline to give evidence, or as the case may be a right to decline to give evidence acknowledged or permitted by the court with competent jurisdiction,</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings cannot be held liable for compensation or indemnification. The Client will also not be entitled to terminate the Agreement on the basis of any damage arisen due to this.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The Client agrees to Alchemist Algo Tradings approaching the Client for statistical research or a client satisfaction survey. If the Client does not want to be approached for research, the Client can make this known.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings retains the right to use the other data of the Client in anonymous form for (statistical) research and database.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p>
                                <h3>Amendment of the General Terms and Conditions</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings has the right to unilaterally amend these General Terms and Conditions. Amendments will also apply with regard to Agreements that have already been concluded.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Alchemist Algo Tradings will inform the Client by email of the amendments.</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The amendments of the General Terms and Conditions will come into effect thirty days after the Client has been informed of the amendments.</p>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">If the Client does not agree to the notified amendments, the Client will have the right to terminate the Agreement.</p><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">&nbsp;</p>
                                <h3>Applicable law and disputes</h3><br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">The law of the Netherlands exclusively applies to all legal relationships that Alchemist Algo Tradings is party to. This also applies if an obligation is wholly or in part executed abroad or if the Client resides abroad.</p><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}